export const checklist = [
  {
    id: 1,
    labels: [
      {
        title: 'Facebook',
        icon: 'graduate',
        color: '#0A0083',
      },
    ],
    type: 'email',
    checked: true,
    disabled: true,
    date: '12:20 am | 12 Sep 2022',
    data: {
      subject: 'RE: Amendment to contract',
      from: 'David Cooper',
      to: ['Maj Yadav', 'David Howard', 'Allen Copper'],
      description: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam neque harum, nesciunt molestiae nemo nam culpa! Asperiores consequuntur deserunt, provident cum minus odio, consequatur fugit ullam accusamus ex ipsa, facere omnis consectetur recusandae suscipit iure et nobis odit praesentium. Tempora dignissimos, iste assumenda ipsam vero eius minus hic excepturi expedita!`,
      actions: ['reply', 'reply-all', 'forward', 'check'],
      thread: [
        {
          id: 1,
          date: '12:20 am | 12 Sep 2022',
          subject: 'RE: Amendment to contract',
          from: 'David Cooper',
          to: ['Maj Yadav', 'David Howard', 'Allen Copper'],
          description: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam neque harum, nesciunt molestiae nemo nam culpa! Asperiores consequuntur deserunt, provident cum minus odio, consequatur fugit ullam accusamus ex ipsa, facere omnis consectetur recusandae suscipit iure et nobis odit praesentium. Tempora dignissimos, iste assumenda ipsam vero eius minus hic excepturi expedita!`,
          actions: ['reply', 'reply-all', 'forward', 'check'],
        },
        {
          id: 2,
          date: '12:20 am | 12 Sep 2022',
          subject: 'RE: Amendment to contract',
          original_subject: 'Amendment to contract',
          from: 'David Cooper',
          to: ['Maj Yadav', 'David Howard', 'Allen Copper'],
          description: `
          <p>Hi Team,</p>
          <br>
          <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam neque harum, nesciunt molestiae nemo nam culpa! Asperiores consequuntur deserunt, provident cum minus odio, consequatur fugit ullam accusamus ex ipsa, facere omnis consectetur recusandae suscipit iure et nobis odit praesentium.</p>
          <br>
          <p>Best Wishes,</p>
          <br>
          <p><b>David Cooper</b></p>
          <p>Product Manager</p>`,
          actions: ['sent', 'alarm'],
        },
        {
          id: 3,
          date: '12:20 am | 12 Sep 2022',
          subject: 'Long example',
          from: 'David Cooper',
          to: ['Maj Yadav', 'David Howard', 'Allen Copper'],
          description: `
          <p>Hi Team,</p>
          <br>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto nihil minima inventore numquam aliquam voluptatibus impedit provident ab, quae ratione laudantium iste? Soluta repudiandae, earum beatae asperiores placeat accusantium! Fuga animi harum, qui assumenda nam libero, ullam molestiae numquam fugit cupiditate laboriosam? Ad, mollitia ducimus fugiat atque asperiores minus animi pariatur quibusdam. Quia fuga veniam quod velit pariatur ad illo perferendis amet placeat beatae excepturi rerum tempore incidunt consectetur, expedita voluptatum dolores explicabo labore cupiditate architecto voluptatem qui distinctio libero? Veritatis quidem ex sed molestias.
          <br>
          Repellendus quis vel vitae animi asperiores necessitatibus sunt ipsa aspernatur eos eaque, vero quae a commodi deleniti dignissimos saepe est eum dolorum, quisquam, illum iste. Aut doloremque unde atque dolor dignissimos amet eum aspernatur ea rerum! Cupiditate officia voluptates, deleniti quo alias laudantium suscipit repellendus odio? Nemo dolore, maxime fugit ipsam non nihil quidem nulla possimus quisquam ducimus dolores voluptatum eligendi minima modi amet exercitationem aspernatur est nisi temporibus deserunt quia quibusdam?
          <br>
          Aliquam adipisci harum saepe facere mollitia eius molestias eligendi amet illo assumenda iste, impedit quod excepturi quam libero officia necessitatibus porro minus.
          <br>
          Id quasi tempore repellendus, incidunt omnis reiciendis eligendi perferendis ex quibusdam? Eos architecto vel et explicabo voluptatum quos, labore fugiat quidem velit excepturi corrupti, nobis quas rem impedit doloribus ratione facilis? Aliquam autem error ad sint, id, vitae necessitatibus laboriosam a, cumque minus commodi eius voluptatem adipisci cupiditate?
          <br>
          Omnis accusamus dicta cum sapiente corporis iure, aut facere. Quisquam est enim amet ut aut error autem rem necessitatibus. Blanditiis nulla sequi rem vitae, obcaecati commodi veritatis amet, placeat aut, totam maxime quae est! Omnis sed nisi beatae voluptatum, autem expedita praesentium illo fuga sapiente, quaerat quos neque facere. Consectetur tempora reiciendis cupiditate nemo harum, quae, molestiae animi itaque consequatur et sed cumque asperiores expedita, corporis ipsum officia numquam error? Amet, repudiandae dolorum!.</p>
          <br>
          <p>Best Wishes,</p>
          <br>
          <p><b>David Cooper</b></p>
          <p>Product Manager</p>`,
          actions: ['sent', 'alarm'],
        },
      ],
    },
  },
  {
    id: 11,
    labels: [
      {
        title: 'SomeLongNameForTesting',
        icon: 'graduate',
        color: '#FF0000',
      },
    ],
    type: 'email',
    checked: true,
    disabled: true,
    date: '12:20 am | 12 Sep 2022',
    data: {
      subject: 'RE: Amendment to contract',
      from: 'David Cooper',
      to: ['Maj Yadav', 'David Howard', 'Allen Copper'],
      description: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam neque harum, nesciunt molestiae nemo nam culpa! Asperiores consequuntur deserunt, provident cum minus odio, consequatur fugit ullam accusamus ex ipsa, facere omnis consectetur recusandae suscipit iure et nobis odit praesentium. Tempora dignissimos, iste assumenda ipsam vero eius minus hic excepturi expedita!`,
      actions: ['reply', 'reply-all', 'sent', 'alarm'],
      thread: [
        {
          id: 1,
          date: '12:20 am | 12 Sep 2022',
          subject: 'RE: Amendment to contract',
          from: 'David Cooper',
          to: ['Maj Yadav', 'David Howard', 'Allen Copper'],
          description: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam neque harum, nesciunt molestiae nemo nam culpa! Asperiores consequuntur deserunt, provident cum minus odio, consequatur fugit ullam accusamus ex ipsa, facere omnis consectetur recusandae suscipit iure et nobis odit praesentium. Tempora dignissimos, iste assumenda ipsam vero eius minus hic excepturi expedita!`,
          actions: ['reply', 'reply-all', 'forward', 'check'],
        },
      ],
    },
  },
  {
    id: 2,
    labels: [
      {
        title: 'UCL',
        icon: 'briefcase',
        color: '#05BEF9',
      },
    ],
    type: 'call',
    checked: true,
    disabled: false,
    date: '12:22 am | 12 Sep 2022',
    data: {
      subject: 'RE: Amendment to contract',
      from: 'David Cooper',
      to: ['Maj Yadav', 'David Howard', 'Allen Copper'],
      description: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam neque harum, nesciunt molestiae nemo nam culpa! Asperiores consequuntur deserunt, provident cum minus odio, consequatur fugit ullam accusamus ex ipsa, facere omnis consectetur recusandae suscipit iure et nobis odit praesentium. Tempora dignissimos, iste assumenda ipsam vero eius minus hic excepturi expedita!`,
      actions: ['sent', 'alarm'],
    },
  },
  {
    id: 3,
    labels: [
      {
        title: 'Facebook',
        icon: 'graduate',
        color: '#FFB800',
      },
    ],
    type: 'status_change',
    date: '12:30 am | 14 Sep 2022',
    data: {
      from: 'In Progress',
      to: 'On Hold',
      actions: ['note_select', 'delete'],
    },
  },
  {
    id: 4,
    labels: [
      // {
      //   title: 'Google',
      //   icon: 'briefcase',
      //   color: '#8EB144',
      // },
    ],
    type: 'note',
    checked: true,
    disabled: false,
    date: '12:22 am | 12 Sep 2022',
    data: {
      description: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam neque harum, nesciunt molestiae nemo nam culpa! Asperiores consequuntur deserunt, provident cum minus odio, consequatur fugit ullam accusamus ex ipsa.`,
      actions: ['note_select', 'delete'],
    },
  },
  {
    id: 5,
    labels: [
      {
        title: 'Facebook',
        icon: 'briefcase',
        color: 'red',
      },
      {
        title: 'Google',
        icon: 'graduate',
        color: 'blue',
      },
    ],
    type: 'task',
    checked: true,
    disabled: false,
    date: '12:22 am | 12 Sep 2022',
    data: {
      description: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam neque harum, nesciunt molestiae nemo nam culpa! Asperiores consequuntur deserunt, provident cum minus odio, consequatur fugit ullam accusamus ex ipsa.`,
      actions: ['delete'],
    },
  },
]