<template>
<div class="dashboard-tabs flex flex-col max-h-full flex-1">
	<div class="dashboard-tabs__header">
		<ul class="list">
			<li
				v-for="tab in tabs"
				:key="tab.id"
				class="tab cursor-pointer"
				:class="{'tab--selected': selected === tab.id}"
				@click="$emit('select', tab.id)"
			>{{tab.label}}</li>
		</ul>
		<div class="additional-controls">
			<slot/>
		</div>
	</div>
	<div
		class="mt-2 flex flex-1 min-w-full overflow-hidden"
	>
		<slot name="content" />
		<template v-if="$slots['content-' + selected]">
			<slot :name="'content-' + selected"/>
		</template>
	</div>
</div>
</template>

<script>
export default {
	name: 'tabs',
	props: {
		tabs: Array,
		selected: [String, Number],
	},
}
</script>

<style lang="scss" scoped>
.dashboard-tabs {
	width: 100%;
	&__header {
		display: flex;
		align-items: center;
		gap: 20px;
		justify-content: space-between;
		
		.list {
			display: flex;
			align-items: center;
			gap: 1rem;
			.tab {
				height: 48px;
				// padding: 2rem 2.5rem;
				min-width: 160px;
				padding: 0 16px;
				// background-color: #efefefb5;
				background-color: rgba(0, 0, 51, 0.067);
				// color: rgba(40, 40, 40, 0.7);
				@apply text-base-content;
				letter-spacing: 0.095em;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 12px 12px 0 0;
				font-size: 14px;
				font-weight: 600;
				border: 1px solid #f0f0f0;
				border-bottom: 0;
				&--selected {
					// background-color: #0D69D5;
					@apply bg-primary;
					@apply border-primary;
					// color: #fff;
					@apply text-white-text;
				}
			}
		}
	}
	&__content {
		margin-top: 20px;
	}
}
</style>