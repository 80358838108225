export default {
  name: "icon-list",
  data() {
    return {
      active: "",
    };
  },
  props: {
    icons: {
      type: Array,
      default: [],
    },
    onSelect: {
      type: Function,
      default: () => {},
    },
    handleClearCallActiveLog: Function,
    activity_count:{
      type: Number,
      default: 0
    }
  },

  methods: {
    getStyles(icon) {
      this.active = icon.key;
      this.handleClearCallActiveLog()
      this.onSelect(icon);
    },
  },
};
