<template>
  <div class="main-wrapper inline-block rounded-2xl p-5">
    <div class="main-container flex items-center gap-4">
      <div class="main-image rounded-full flex-shrink-0  w-16 h-16 flex items-center text-3xl text-gray-800 justify-center" :class="getRandomColors()">
        <!-- <img
        src=""
        alt=""
        class="profile-image rounded-full w-28 h-28"
      /> -->
        {{nameToShort(name)}}
      </div>
      <div class="main-details-container flex flex-col gap-3 overflow-hidden flex-1 max-w-full">
        <div v-tippy='{ arrow : true }' :content="name" class="main-title font-semibold text-lg capitalize truncate">
          {{ name }}
        </div>
        <div class="main-details-group gap-2 flex flex-col">
          <div class="flex flex-1 gap-3">
            <CakeIcon class="w-4" />
            <span class="text-sm">DD/MM/YY</span>
          </div>
          <div class="flex-1 flex gap-3 ">
            <MailIcon class="w-4" />
            <span v-tippy='{ arrow : true }'  :content="email"  class="text-sm flex-1 truncate">{{ email }}</span>
          </div>
          <div class="flex-1 flex gap-3">
            <TelIcon class="w-4" />
            <span class="text-sm">{{ tel }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CakeIcon from "@/assets/icons/svg/birthday.svg";
import MailIcon from "@/assets/icons/svg/email.svg";
import TelIcon from "@/assets/icons/svg/tel.svg";
import axios from "@/axios";
import {getRandomColors, nameToShort} from "@/plugins/functions"
export default {
  name: "ProfileCard",
  data() {
    return {
      id: "",
      name: "---",
      email: "---",
      tel: "---",
    };
  },
  components: {
    CakeIcon,
    MailIcon,
    TelIcon,
  },
  async mounted() {
    if (this.$route.query["caseid"]) {
      this.id = this.$route.query["caseid"];
    }
    // this.id = "224f67c8-e6e1-4542-a91a-9aba7b91bca6";
    await this.getCaseDetails();
  },
  methods: {
    nameToShort,
    getRandomColors,
    async getCaseDetails() {
      try {
        const url = `/case/candidate/${this.id}/client`;
        let data = await axios.get(url);
        const { first_name, last_name, email, phone } = data.data.data;
        this.name = `${first_name} ${last_name}`;
        this.email = `${email}`;
        this.tel = `${phone}`;
        this.$emit("getCaseDetails", data?.data?.data)
      } catch (error) {
        console.log('error :>> ', error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main-wrapper {
  background-color: #f2f2f2;
}
</style>
