import axios from "@/axios";

const getChecksList = async () => {
    let url = `/tenant-check/client-checks`;
    try {
        let { data } = await axios.get(url);
        return data.data
    }
    catch (error) {
        console.log(error, "Error")
    }
    // this.columns = columns;
}

const getPackagesCheck = async (packageId) => {
    const url = `/screening-package/${packageId}/check`
    try {
        const { data } = await axios.get(url)
        return data
    } catch (error) {
        console.warn("some error in fetching package checks..")
    }
}

export { getChecksList, getPackagesCheck }



