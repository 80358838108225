
import Button from "@/components/button";
import Checkbox from "@/components/checkbox";
import Loader from "@shared/loader";
import modalContent from "@shared/modal-content";
import ListView from "@shared/components/list-view";
import { getChecksList } from "@/modules/checks/services.js";
import axios from "@/axios";

export default {
    name: "add-check-modal",
    components: {
        Loader,
        Button,
        Checkbox,
        modalContent,
        ListView,
    },
    data() {
        return {
            checkName: "",
            selectedChecks: [],
            loadingLists: false,
            allChecks: [],
            addingChecks: false,
            search: '',
        };
    },
    props: {
        caseChecks: {
            type: Array,
            default: [],
        },
    },
    mounted() {
        this.closeModal();
    },
    computed: {
        checks() {
            return this.allChecks.filter((chk) => !this.caseChecks.some(el => el.check_id === chk.id))
        },
        filteredChecks() {
            return this.checks.filter((el) => el.name?.toLowerCase().includes(this.search.toLowerCase()));
        }
    },
    methods: {
        closeModal() {
            this.$refs["add-check-modal"].hideModal();
        },
        async showCheckModal() {
            this.checkName = "";
            this.search = '';
            this.$refs["add-check-modal"].showModal();
            this.loadingLists = true
            await this.fetchChecksList();
            this.loadingLists = false
        },

        async fetchChecksList() {
            const client = this.$store.getters.getClientId;
			console.log(this.$store.getters);
            let checksData = await getChecksList(client, 1000);
            this.allChecks = checksData;
            // this.columns = columns;
            this.isLoading = false;
        },
        toggleCheck(check) {
            const match = this.selectedChecks.findIndex((chk) => chk.id === check.id);
            if (match > -1)
                this.selectedChecks.splice(match, 1);
            else
                this.selectedChecks.push(check);

            let index = this.allChecks.findIndex((chk)=>chk.id === check.id)
            if(match> -1){
                this.allChecks.splice(index,1)
                this.allChecks.splice(this.selectedChecks.length,0,check)
            }
            else{
                this.allChecks.splice(index,1)
                this.allChecks.unshift(check)
            }

        },
        selectAll(value) {
            if (value)
                this.selectedChecks = this.checks;
            else
                this.selectedChecks = [];
        },
        async addCheckToCase() {
            this.addingChecks = true;
            const case_id = this.$route.query.caseid;
            const url = `/case/addon-checks/${case_id}/client`;
            const payload = {
                addon_checks: this.selectedChecks.map((chk) => chk.id),
            }
            
            try {
                await axios.post(url, payload);
                this.$toast.success("Checks added to case.");
                this.$emit("checksAdded")
            } catch (error) {
                this.$toast.warning(error?.response?.data?.detail || "Could not add checks to case.");
            }
            this.closeModal();
            this.addingChecks = false;
        },

        handleFilterResult(search) {
            return this.search = search;
        },
        onBeforeClose() {
            this.selectedChecks = [];
            this.$emit("before-close");
        },
    },
};